import * as React from "react";
import { pushDataLayer } from "../utils/gtm";

if (typeof window !== "undefined" && "localStorage" in window) {
  const t = window.localStorage.getItem("gtm:traffic_type");
  if (t) pushDataLayer({ event: "traffic_type", traffic_type: t });
}

export default function Root({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}
