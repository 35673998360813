import * as React from "react";
import Root from "./src/components/Root";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => <Root>{element}</Root>;

if (window.origin.indexOf("localhost") > -1) {
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag("consent", "update", {
    analytics_storage: "granted",
    functionality_storage: "granted",
    personalization_storage: "granted",
  });
  window.dataLayer.push({ event: "cookie_consent_update" });
}
